/* @font-face {
  font-family: 'Some Local Font';
  src: url('../fonts/SomeLocalFont.otf');
  font-weight: normal;
  font-style: normal;
} */

/* AC Hotels */
@font-face {
    font-family: 'CenturySchoolbookPro';
    src: url('https://cache.marriott.com/aka-fonts/AC/CenturySchoolbookPro.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Aloft */
  @font-face {
      font-family: 'GalaxiePolaris-Bold';
      src:
          url('https://cache.marriott.com/aka-fonts/Aloft/GalaxiePolaris-Bold.otf') format('otf'),
          url('https://cache.marriott.com/aka-fonts/Aloft/GalaxiePolaris-Bold.woff') format('woff'),
          url('https://cache.marriott.com/aka-fonts/Aloft/GalaxiePolaris-Bold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Autograph */
  @font-face {
      font-family: 'DINNeuzeitGroteskStd-Light';
      src:
              url('https://cache.marriott.com/aka-fonts/Autograph/DINNeuzeitGroteskStd-Light.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Autograph/DINNeuzeitGroteskStd-Light.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Autograph/DINNeuzeitGroteskStd-Light.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Bulgari */
  @font-face {
      font-family: 'FuturaStd-Medium';
      src:
              url('https://cache.marriott.com/aka-fonts/Bulgari/FuturaStd-Medium.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Bulgari/FuturaStd-Medium.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Bulgari/FuturaStd-Medium.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Delta Hotels */
  @font-face {
      font-family: 'ClassicGrotesqueStd-Book';
      src:
              url('https://cache.marriott.com/aka-fonts/Delta/ClassicGrotesqueStd-Book.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Delta/ClassicGrotesqueStd-Book.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Delta/ClassicGrotesqueStd-Book.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Design Hotels */
  @font-face {
      font-family: 'PlantinMTStd-Regular';
      src:
              url('https://cache.marriott.com/aka-fonts/DesignHotels/PlantinMTStd-Regular.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/DesignHotels/PlantinMTStd-Regular.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/DesignHotels/PlantinMTStd-Regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Edition */
  @font-face {
      font-family: 'DidotLTStd-Roman';
      src:
              url('https://cache.marriott.com/aka-fonts/Edition/DidotLTStd-Roman.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Edition/DidotLTStd-Roman.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Edition/DidotLTStd-Roman.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Element */
  @font-face {
      font-family: 'FuturaStd-Book';
      src:
              url('https://cache.marriott.com/aka-fonts/Element/FuturaStd-Book.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Element/FuturaStd-Book.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Element/FuturaStd-Book.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Fairfield */
  @font-face {
      font-family: 'TimesEighteenStd-Roman';
      src:
              url('https://cache.marriott.com/aka-fonts/Fairfield/TimesEighteenStd-Roman.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Fairfield/TimesEighteenStd-Roman.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Fairfield/TimesEighteenStd-Roman.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Four Points */
  @font-face {
      font-family: 'ClarendonLTStd';
      src:
              url('https://cache.marriott.com/aka-fonts/FourPoints/ClarendonLTStd.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/FourPoints/ClarendonLTStd.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/FourPoints/ClarendonLTStd.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Gaylord */
  @font-face {
      font-family: 'proxima-nova-semibold';
      src:
              url('https://cache.marriott.com/aka-fonts/Gaylord/proxima-nova-semibold.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Gaylord/proxima-nova-semibold.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Gaylord/proxima-nova-semibold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* JW Marriott */
  @font-face {
      font-family: 'AvenirNextLTPro-Light';
      src:
              url('https://cache.marriott.com/aka-fonts/JW/AvenirNextLTPro-Light.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/JW/AvenirNextLTPro-Light.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/JW/AvenirNextLTPro-Light.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Le Meridien */
  @font-face {
      font-family: 'GriffithGothic-Light';
      src:
              url('https://cache.marriott.com/aka-fonts/LeMeridien/GriffithGothic-Light.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/LeMeridien/GriffithGothic-Light.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/LeMeridien/GriffithGothic-Light.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* MEA */
  @font-face {
      font-family: 'proxima-nova-semibold';
      src:
              url('https://cache.marriott.com/aka-fonts/MEA/proxima-nova-semibold.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/MEA/proxima-nova-semibold.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/MEA/proxima-nova-semibold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Marriott Hotels */
  @font-face {
      font-family: 'EffraMedium';
      src:
              url('https://cache.marriott.com/aka-fonts/Marriott/EffraMedium.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Marriott/EffraMedium.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Marriott/EffraMedium.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Marriott Vacation Club */
  @font-face {
      font-family: 'BaskervilleMTStd-Regular';
      src:
              url('https://cache.marriott.com/aka-fonts/MarriottVacationClub/BaskervilleMTStd-Regular.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/MarriottVacationClub/BaskervilleMTStd-Regular.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/MarriottVacationClub/BaskervilleMTStd-Regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Moxy Hotels */
  @font-face {
      font-family: 'GillSansMTStd-Book';
      src:
              url('https://cache.marriott.com/aka-fonts/Moxy/GillSansMTStd-Book.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Moxy/GillSansMTStd-Book.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Moxy/GillSansMTStd-Book.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Protea Hotels */
  @font-face {
      font-family: 'SlateStd-Bk';
      src:
              url('https://cache.marriott.com/aka-fonts/Protea/SlateStd-Bk.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Protea/SlateStd-Bk.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Protea/SlateStd-Bk.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Residence Inn */
  @font-face {
      font-family: 'SackersGothicStd-Medium';
      src:
              url('https://cache.marriott.com/aka-fonts/ResidenceInn/SackersGothicStd-Medium.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/ResidenceInn/SackersGothicStd-Medium.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/ResidenceInn/SackersGothicStd-Medium.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Sheraton */
  @font-face {
      font-family: 'GT-Super-Display-Light';
      src:
              url('https://cache.marriott.com/aka-fonts/Sheraton/GT-Super-Display-Light.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Sheraton/GT-Super-Display-Light.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Sheraton/GT-Super-Display-Light.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Springhill Suites */
  @font-face {
      font-family: 'TipperaryET-Semibold';
      src:
              url('https://cache.marriott.com/aka-fonts/SHS/TipperaryET-Semibold.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/SHS/TipperaryET-Semibold.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/SHS/TipperaryET-Semibold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* St Regis */
  @font-face {
      font-family: 'RoosStRegisText';
      src:
              url('https://cache.marriott.com/aka-fonts/StRegis/RoosStRegisText.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/StRegis/RoosStRegisText.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/StRegis/RoosStRegisText.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* The Luxury Collection */
  @font-face {
      font-family: 'Acta-Medium';
      src:
              url('https://cache.marriott.com/aka-fonts/TLC/Acta-Medium.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/TLC/Acta-Medium.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/TLC/Acta-Medium.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* TownePlace Suites */
  @font-face {
      font-family: 'hurmegeometricsans_no3_5_semibold-webfont';
      src:
              url('https://cache.marriott.com/aka-fonts/TowneplaceSuites/hurmegeometricsans_no3_5_semibold-webfont.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/TowneplaceSuites/hurmegeometricsans_no3_5_semibold-webfontm.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/TowneplaceSuites/hurmegeometricsans_no3_5_semibold-webfont.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Tribute Portfolio */
  @font-face {
      font-family: 'AvenirLTStd-Heavy';
      src:
              url('https://cache.marriott.com/aka-fonts/Tribute/AvenirLTStd-Heavy.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Tribute/AvenirLTStd-Heavy.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Tribute/AvenirLTStd-Heavy.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* Westin */
  @font-face {
      font-family: 'FreigDisProMed';
      src:
              url('https://cache.marriott.com/aka-fonts/Westin/FreigDisProMed.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/Westin/FreigDisProMed.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/Westin/FreigDisProMed.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  /* W Hotels */
  @font-face {
      font-family: 'WSupreme-Medium';
      src:
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupreme-Medium.otf') format('otf'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-Medium.woff2') format('woff2'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-Medium.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'WSupreme-Regular';
      src:
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupreme-Regular.otf') format('otf'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-Regular.woff2') format('woff2'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'WSupreme-SemiBold';
      src:
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupreme-SemiBold.otf') format('otf'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-SemiBold.woff2') format('woff2'),
              url('https://d1ibh6b9498ljl.cloudfront.net/wsupreme/WSupremeWeb-SemiBold.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'WSansNew-Bold';
      src:
              url('https://cache.marriott.com/aka-fonts/WHotels/WSansNew-Bold.otf') format('otf'),
              url('https://cache.marriott.com/aka-fonts/WHotels/WSansNew-Bold.woff') format('woff'),
              url('https://cache.marriott.com/aka-fonts/WHotels/WSansNew-Bold.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'apercu_bold_pro';
      src:
              url('https://d1ibh6b9498ljl.cloudfront.net/dlz2/fonts/apercu_bold_pro.otf') format('otf'),
              url('https://d1ibh6b9498ljl.cloudfront.net/dlz2/fonts/apercu_bold_pro.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }
  
  @font-face {
    font-family: 'Caslon540LTStd-Roman';
    src: url('../fonts/Caslon540LTStd-Roman.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ProximaNova-Bold';
    src: url('../fonts/ProximaNova-Bold.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ProximaNova-Regular';
    src: url('../fonts/ProximaNova-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  
  
  @font-face {
    font-family: 'ProximaNova-SemiBold';
    src: url('../fonts/ProximaNova-Semibold.otf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DomaineDisplay-Bold';
    src: local('DomaineDisplay-Bold'),
          url("https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.otf") format("otf"),
          url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.woff2') format('woff2'),
          url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.woff') format('woff'),
          url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.svg') format('svg');
  }
  
  @font-face {
    font-family: 'DomaineDisplay-Bold';
    src: url('../fonts/DomaineDisplay-Bold.otf'),
      url('../fonts/DomaineDisplay-Bold.woff2') format('woff2'),
      url('../fonts/DomaineDisplay-Bold.woff') format('woff'),
      url('../fonts/DomaineDisplay-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DomaineDisplay-BoldItalic';
    src: url('../fonts/DomaineDisplay-BoldItalic.otf'),
      url('../fonts/DomaineDisplay-BoldItalic.woff2') format('woff2'),
      url('../fonts/DomaineDisplay-BoldItalic.woff') format('woff'),
      url('../fonts/DomaineDisplay-BoldItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Swiss721BT';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Swiss721BT.otf'),
      url('../fonts/Swiss721BT.woff2') format('woff2'),
      url('../fonts/Swiss721BT.woff') format('woff'),
      url('../fonts/Swiss721BT.svg') format('svg');
  }

@font-face {
  font-family: 'Caslon540LTStd-Roman';
  src: url('../fonts/Caslon540LTStd-Roman.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.otf');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('../fonts/ProximaNova-Semibold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-Bold';
  src: local('DomaineDisplay-Bold'),
        url("https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.otf") format("otf"),
        url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.woff2') format('woff2'),
        url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.woff') format('woff'),
        url('https://s3.amazonaws.com/isistudio-public/marriott-ld/DomaineDisplay-Bold/DomaineDisplay-Bold.svg') format('svg');
}

@font-face {
  font-family: 'DomaineDisplay-Bold';
  src: url('../fonts/DomaineDisplay-Bold.otf'),
    url('../fonts/DomaineDisplay-Bold.woff2') format('woff2'),
    url('../fonts/DomaineDisplay-Bold.woff') format('woff'),
    url('../fonts/DomaineDisplay-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-BoldItalic';
  src: url('../fonts/DomaineDisplay-BoldItalic.otf'),
    url('../fonts/DomaineDisplay-BoldItalic.woff2') format('woff2'),
    url('../fonts/DomaineDisplay-BoldItalic.woff') format('woff'),
    url('../fonts/DomaineDisplay-BoldItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Swiss721BT';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Swiss721BT.otf'),
    url('../fonts/Swiss721BT.woff2') format('woff2'),
    url('../fonts/Swiss721BT.woff') format('woff'),
    url('../fonts/Swiss721BT.svg') format('svg');
}

@font-face {
  font-family: 'Swiss721BT';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Swiss721BT-BoldCondensed.otf'),
    url('../fonts/Swiss721BT-BoldCondensed.woff2') format('woff2'),
    url('../fonts/Swiss721BT-BoldCondensed.woff') format('woff'),
    url('../fonts/Swiss721BT-BoldCondensed.svg') format('svg');
}

@font-face {
    font-family: 'Gotham';
    src:
      url('https://d1ibh6b9498ljl.cloudfront.net/gotham/Gotham-Book.otf') format('otf'),
      url('https://d1ibh6b9498ljl.cloudfront.net/gotham/Gotham-Book.woff') format('woff'),
      url('https://d1ibh6b9498ljl.cloudfront.net/gotham/Gotham-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Thyga';
    src:
      url('https://d1ibh6b9498ljl.cloudfront.net/thyga/thyga.otf') format('otf'),
      url('https://d1ibh6b9498ljl.cloudfront.net/thyga/thyga.woff') format('woff'),
      url('https://d1ibh6b9498ljl.cloudfront.net/thyga/thyga.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
